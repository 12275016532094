import React from "react";
import NumberFormat from "react-number-format";
import { AutocompleteField } from "components";
import { AddressAutoComplete, OnePercentCommissionValues } from "models";
import OnePercentCommissionService from "services/OneCommissionService";

export default function OnePercentCommission() {
  const resultRef = React.useRef<HTMLDivElement>(null);
  const howItWorkRef = React.useRef<HTMLDivElement>(null);

  const [data, setData] = React.useState<OnePercentCommissionValues>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const onSearch = (q: string) => OnePercentCommissionService.getAddresses(q);

  const onSelect = async (selected: any) => {
    const { extra } = selected as AddressAutoComplete;
    try {
      setLoading(true);
      let mak = extra?.mak;
      if ((extra?.mak || "").split(",").length > 1) {
        mak = extra?.baseMAK;
      }

      const propertyData = await OnePercentCommissionService.getProperty(
        extra?.addressLine1 || "",
        extra?.city || "",
        extra?.state || "",
        extra?.postalCode || "",
        mak || ""
      );

      setData(propertyData);
      setLoading(false);
      setTimeout(() => {
        resultRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    } catch {
      setLoading(false);
    }
  };

  const scrollToHowItWork = () => {
    howItWorkRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div>
      <div className="wow fadeInUp delay-02s h-[calc(100vh-78px)] bg-[url('../images/1percentcommission-bgcover.jpg')] bg-no-repeat bg-center bg-cover flex items-center justify-center">
        <div className="col-md-8 bg-slate-100/[.7] rounded p-10 text-center">
          <h1 className="font-semibold text-6xl mb-10">
            Sell Your House For 1% Commission
          </h1>
          <AutocompleteField
            data={onSearch}
            minLengthAutocomplete={4}
            placeholder="Enter your property address to see how much you could save (4 characters…)"
            onSelect={onSelect}
          />
          <div className="mt-10">
            <span
              className="text-3xl cursor-pointer"
              onClick={scrollToHowItWork}
            >
              How it works?
            </span>
          </div>
          {loading && (
            <h5 className="mt-5">
              <span className="fa fa-refresh fa-spin"></span> Loading...
            </h5>
          )}
        </div>
      </div>

      {data && (
        <div ref={resultRef} className="py-44">
          <div className="container wow fadeInUp delay-03s">
            <div className="text-center mb-10">
              <h4 className="text-5xl text-blue-800 font-semibold">
                Your Home Value:
                <NumberFormat
                  value={data?.estimatedValueNumber || 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  className="ml-2"
                  decimalScale={1}
                />
              </h4>
            </div>

            <table border={1} width="90%" className="properties_info">
              <thead>
                <tr>
                  <td></td>
                  <td className="bg-orange-500 border p-3 text-white text-center font-semibold">
                    1% Total Commission Program
                  </td>
                  <td className="bg-gray-300 border p-3 text-center font-semibold">
                    Traditional Home Sale
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-3 border font-semibold">Total Commission</td>
                  <td className="p-3 border text-center font-semibold">
                    <NumberFormat
                      value={data?.oneCommission || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={1}
                    />
                  </td>
                  <td className="p-3 border text-center font-semibold">
                    <NumberFormat
                      value={data?.traditionalCommission || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-3 border font-semibold">Your Net Sale</td>
                  <td className="p-3 border text-center font-semibold">
                    <NumberFormat
                      value={data?.oneSale || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={1}
                    />
                  </td>
                  <td className="p-3 borde text-center font-semibold">
                    <NumberFormat
                      value={data?.traditionalSale || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={1}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-3 border font-semibold">You Save</td>
                  <td className="p-3 border text-center font-semibold">
                    <NumberFormat
                      value={data?.yourSaved || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={1}
                    />
                  </td>
                  <td className="p-3 border text-center font-semibold">$0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div ref={howItWorkRef} className="py-44">
        <div className="container wow fadeInUp delay-03s">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-people"></i>
                </div>
                <div className="detail">
                  <h3>Contact Us</h3>
                  <p>
                    Submit the contact form above. An experienced local agent
                    will help you make informed decision about selling your home
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-internet"></i>
                </div>
                <div className="detail">
                  <h3>List your Property</h3>
                  <p>
                    We know using enhanced visual prepresentation and staging
                    increase selling time and home value.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-technology-1"></i>
                </div>
                <div className="detail">
                  <h3>Find Buyers</h3>
                  <p>
                    Tap into our network of pre-approved buyers. We find and
                    secure the top offer for your home
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-symbol-1"></i>
                </div>
                <div className="detail">
                  <h3>Close The Sale</h3>
                  <p>
                    Our team will guide you through piles of paperwork. We help
                    take the stress out of the sales transition.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-5xl font-semibold mt-6">
                Call (909) 672-9300
              </h3>
              <div className="mt-3">Broker DRE#01166007</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
