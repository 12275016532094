import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfileModel } from "models";
import { login } from "store/async-thunk/AuthSyncThunk";

interface AuthState {
    user: UserProfileModel,
    token: string | null,
    error: string | undefined
}

const initialState: AuthState = {
    user: {},
    token: null,
    error: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        logOut: (state) => {
            state.token = initialState.token;
            state.user = initialState.user;
        }
    },
    extraReducers: builder => {
        builder.addCase(login.pending, (state) => {
            state.error = '';
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.error = action.error.message
        });
    }
})

export const { setToken, logOut } = authSlice.actions;
export default authSlice.reducer;