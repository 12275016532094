import React from "react";
import debounce from "lodash/debounce";
import set from "lodash/set";
import isEqual from "lodash/isEqual";

interface ISelectOptions {
  label: string;
  value: string | number;
  extra: any
}

type SearchFunction = (q: string) => Promise<any[]>;

type AutocompleteProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    data?: ISelectOptions[] | SearchFunction;
    onSelect?: (option: ISelectOptions) => void;
    delay?: number;
    displayField?: "value" | "label";
    minLengthAutocomplete?: number;
  },
  "value" | "onChange"
>;

export default function AutocompleteField({
  onSelect = () => {},
  delay = 300,
  data = [],
  displayField = "label",
  minLengthAutocomplete = 1,
  ...props
}: AutocompleteProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<ISelectOptions[]>([]);
  const [focused, setFocus] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onFocus = () => setFocus(true);

  const debouncedSearch = React.useRef(
    debounce(async (criteria) => {
      setLoading(!!criteria);
      setOpen(!!criteria);
      if (!criteria) return;
      if (
        typeof data === "function" &&
        criteria.length >= minLengthAutocomplete
      ) {
        setOptions(await data(criteria));
      }
      setLoading(false);
    }, delay)
  ).current;

  const searchFilter = (criteria: string | undefined = "") => {
    if (criteria?.length <= minLengthAutocomplete) return;
    if (!criteria?.trim() && open) setOpen(false);
    if (data instanceof Array && criteria?.trim()) {
      setOpen(true);
      setOptions(
        data.filter((option) => {
          const label = option.label.toLowerCase();
          const value = option.value.toString().toLowerCase();
          const q = criteria.trim().toLowerCase();

          return (
            label.includes(q) ||
            value.toString().includes(q) ||
            isEqual(label, q) ||
            isEqual(value, q)
          );
        })
      );
    }
  };

  const onSearch = () =>
    typeof data === "function"
      ? debouncedSearch(inputRef.current?.value)
      : searchFilter(inputRef.current?.value);

  const onSelectOption = React.useCallback((option: ISelectOptions) => {
    set(inputRef, "current.value", option[displayField]);
    onSelect && onSelect(option);
    setOpen(false);
    setOptions([]);
  }, [displayField, onSelect]);

  const ResultComp = React.useMemo(() => {
    return (
      open && (
        <ul className="absolute bg-white inset-x-px text-left z-50 overflow-y-scroll max-h-72">
          {options.length > 0 &&
            options.map((option) => (
              <li
                className="px-5 pt-5 last:pb-5 cursor-pointer hover:text-slate-300"
                key={option.value}
                onClick={() => onSelectOption(option)}
              >
                {option[displayField]}
              </li>
            ))}

          {options.length === 0 &&
            focused &&
            inputRef.current?.value &&
            !loading && (
              <li className="p-5 text-slate-300 text-center">
                No result found.
              </li>
            )}
        </ul>
      )
    );
  }, [options, focused, loading, open, displayField, onSelectOption]);

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  React.useEffect(() => {
    if (!inputRef.current?.value) setOptions([]);
  }, [inputRef.current?.value]);

  return (
    <div className="relative my-4">
      <input
        {...props}
        ref={inputRef}
        onInput={onSearch}
        onFocus={onFocus}
        className={`form-control h-20 py-3 ${props.className}`}
      />
      {ResultComp}
      {loading && (
        <i className="fa fa-refresh fa-spin absolute right-5 top-6 text-2xl"></i>
      )}
    </div>
  );
}
