import PlaceSelect, { ResponseSelect } from "./PlaceSelect";
import {
  CONSIDERATIONS,
  CONVINENCES,
  FEATURES_IN_HOUSE,
  PET_FRIENDLY,
  THE_VIEWS,
  DEAL_BREAKERS,
  YOUR_COMMUNITIES,
  FIX_PRICES,
  ROOMS,
} from "common/Constants";
import { useDispatch } from "react-redux";
import { updateSurvey } from "store/reducers/ApartmentForm.reducer";
import get from "lodash/get";
import Scrollbars from "react-custom-scrollbars";
import { CheckboxGroup, SelectRange } from "components";
import { useAppSelector } from "store";
import React from "react";

export default function SearchForm() {
  const dispatch = useDispatch();
  const formValues = useAppSelector((s) => s.apartmentForm.formValues);
  const preferedLocations = React.useMemo(() => {
    return get(formValues, "survey.preferLocations", []).map(
      (s: any) => s.source
    );
  }, [JSON.stringify(formValues)]);

  const zipCode = React.useMemo(
    () => get(formValues, "survey.zipSource"),
    [JSON.stringify(formValues)]
  );

  const changeParam = (values: any = {}) => dispatch(updateSurvey(values));

  return (
    <div className="flex-1 max-h-[calc((100vh-78px) / 2)]">
      <Scrollbars autoHide>
        <div className="p-7">
          <div className="mb-2">
            <h2 className="pull-left uppercase font-semibold">Search</h2>
            <div className="clearfix"></div>
          </div>
          <div>
            <label>Prefer Locations</label>
            <div className="row">
              <div className="col-md-12">
                <PlaceSelect
                  selectProps={{
                    placeholder: "Prefered Locations...",
                    value: preferedLocations,
                  }}
                  onSelect={(preferLocations) =>
                    changeParam({ preferLocations })
                  }
                  isMulti
                />
              </div>
            </div>

            <label className="mt-4">Zip Code</label>
            <div className="row">
              <div className="col-md-12">
                <PlaceSelect
                  selectProps={{
                    placeholder: "Zip Code",
                    value: zipCode,
                    isClearable: true,
                  }}
                  onSelect={(
                    selected: ResponseSelect | ResponseSelect[] | null
                  ) =>
                    changeParam({
                      zipCode: (selected as ResponseSelect)?.zip,
                      zipSource: (selected as ResponseSelect)?.source,
                    })
                  }
                />
              </div>
            </div>

            <SelectRange
              fieldLabel="Rent Price"
              fromPlaceholder="Min Price"
              toPlaceholder="Max Price"
              options={FIX_PRICES}
              isClearable
              value={[
                +(formValues?.survey?.priceRangeFrom || 0),
                +(formValues?.survey?.priceRangeTo || 0),
              ]}
              onChange={([min, max]) =>
                changeParam({
                  priceRangeFrom: min?.toString(),
                  priceRangeTo: max?.toString(),
                })
              }
            />

            <SelectRange
              fieldLabel="Bed Room"
              fromPlaceholder="Min Room"
              toPlaceholder="Max Room"
              options={ROOMS}
              isClearable
              value={[
                +(formValues?.survey?.minBedRoom || 0),
                +(formValues?.survey?.maxBedRoom || 0),
              ]}
              onChange={([min, max]) =>
                changeParam({
                  minBedRoom: min?.toString(),
                  maxBedRoom: max?.toString(),
                })
              }
            />

            <SelectRange
              fieldLabel="Bath Room"
              fromPlaceholder="Min Room"
              toPlaceholder="Max Room"
              options={ROOMS}
              isClearable
              value={[
                +(formValues?.survey?.minBathRoom || 0),
                +(formValues?.survey?.maxBathRoom || 0),
              ]}
              onChange={([min, max]) =>
                changeParam({
                  minBathRoom: min?.toString(),
                  maxBathRoom: max?.toString(),
                })
              }
            />

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="Features"
                  options={FEATURES_IN_HOUSE}
                  name="featuresInHouse"
                  value={formValues?.survey?.featuresInHouse}
                  onChange={(featuresInHouse) =>
                    changeParam({
                      featuresInHouse,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="Convenience To"
                  options={CONVINENCES}
                  name="convinences"
                  value={formValues?.survey?.convinences}
                  onChange={(convinences) =>
                    changeParam({
                      convinences,
                    })
                  }
                />
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="Consideration of School Factor?"
                  options={CONSIDERATIONS}
                  name="considerations"
                  value={formValues?.survey?.considerations}
                  onChange={(considerations) =>
                    changeParam({
                      considerations,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="The View"
                  options={THE_VIEWS}
                  name="theViews"
                  value={formValues?.survey?.theViews}
                  onChange={(theViews) =>
                    changeParam({
                      theViews,
                    })
                  }
                />

                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="Pet Friendly"
                  options={PET_FRIENDLY}
                  name="petFriendlyPlace"
                  value={formValues?.survey?.petFriendlyPlace}
                  disableOtherWhenSelectedValue="None"
                  onChange={(petFriendlyPlace) =>
                    changeParam({
                      petFriendlyPlace,
                    })
                  }
                />
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="The Deal Breaker For You?"
                  options={DEAL_BREAKERS}
                  name="dealBreakers"
                  value={formValues?.survey?.dealBreakers}
                  onChange={(dealBreakers) =>
                    changeParam({
                      dealBreakers,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <CheckboxGroup
                  labelClassName="margin-t-10"
                  label="Your Community?"
                  options={YOUR_COMMUNITIES}
                  name="yourCommunities"
                  value={formValues?.survey?.yourCommunities}
                  onChange={(yourCommunities) =>
                    changeParam({
                      yourCommunities,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}
