import React from "react";
import Select, { SingleValue } from "react-select";
import get from "lodash/get";

type Option = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

type SelectRangeProps = {
  options: Option[];
  value?: any[];
  fromPlaceholder: string;
  toPlaceholder: string;
  fieldLabel: string;
  isClearable?: boolean;
  onChange?: (value: any[]) => void
};

export default function SelectRange({ value, ...props }: SelectRangeProps) {
  const [selectedValue, setSelectedValue] = React.useState<any[]>([
    undefined,
    undefined,
  ]);
  const bodyTarget = React.useMemo(() => document.querySelector("body"), []);
  const onMinChange = (selected: SingleValue<Option>) => {
    setSelectedValue([selected, selectedValue?.[1]]);
    props.onChange && props.onChange([selected?.value, selectedValue?.[1]?.value])
  }
  const onMaxChange = (selected: SingleValue<Option>) => {
    setSelectedValue([selectedValue?.[0], selected]);
    props.onChange && props.onChange([selectedValue?.[0]?.value, selected?.value])
  }

  const options = React.useMemo(() => {
    const min = props.options.map((otp) => ({
      ...otp,
      isDisabled: otp.value > get(selectedValue, "[1].value"),
    }));
    const max = props.options.map((otp) => ({
      ...otp,
      isDisabled: otp.value < get(selectedValue, "[0].value"),
    }));
    return {
      min,
      max,
    };
  }, [selectedValue, JSON.stringify(props.options)]);

  React.useEffect(() => {
    const newVal = (value || []).map((v) =>
      props.options.find((opt) => opt.value === v)
    );
    setSelectedValue(newVal);
  }, [JSON.stringify(value)]);

  return (
    <>
      <label className="mt-4">{props.fieldLabel}</label>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Select
            options={options.min}
            placeholder={props.fromPlaceholder}
            menuPortalTarget={bodyTarget}
            isClearable={props.isClearable}
            onChange={onMinChange}
            value={selectedValue[0]}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Select
            options={options.max}
            placeholder={props.toPlaceholder}
            menuPortalTarget={bodyTarget}
            isClearable={props.isClearable}
            value={selectedValue[1]}
            onChange={onMaxChange}
          />
        </div>
      </div>
    </>
  );
}
