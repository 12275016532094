import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { SOCIAL_LINKS, COMPANY_INFO } from "common/Constants";

export default function Footer() {
  const FooterTop = React.useMemo(
    () => (
      <div className="footer-top">
        <div className="row flex items-center justify-center">
          <div className="col-lg-5 col-md-4 col-sm-3 col-xs-12">
            <div className="logo-2 flex">
              <Link to={"/"}>
                <img
                  src={require("assets/images/logo-white-horizontal.png")}
                  alt="footer-logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12"></div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
            <ul className="social-list clearfix">
              {SOCIAL_LINKS.map(({ url, iconCls }) => (
                <li key={iconCls}>
                  <a href={url}>
                    <i className={iconCls}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ),
    []
  );

  const ContactUs = React.useMemo(
    () => (
      <div className="footer-item">
        <div className="main-title-2">
          <h1>Contact Us</h1>
        </div>
        <p>Sales & Support</p>
        <ul className="personal-info">
          {COMPANY_INFO.map((item) => (
            <li key={item.iconCls}>
              <i className={item.iconCls}></i>
              {item.url ? <a href={item.url}>{item.text}</a> : item.text}
            </li>
          ))}
        </ul>
      </div>
    ),
    []
  );

  return (
    <>
      <footer className="main-footer clearfix">
        <div className="container">
          {FooterTop}
          <div className="footer-info">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                {ContactUs}
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-item">
                  <div className="main-title-2">
                    <h1>Links</h1>
                  </div>
                  <ul className="links">
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>
                      <Link to={"/about-us"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={"/blog"}>Blog</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-item tags-box">
                  <div className="main-title-2">
                    <h1>Tags</h1>
                  </div>
                  <ul className="tags">
                    <li>
                      <Link to={"#"}>Buy Property</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Rent</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Apartment Search</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Find Neighborhood</Link>
                    </li>
                    <li>
                      <Link to={"#"}>Sell Houses</Link>
                    </li>
                    <li>
                      <Link to={"#"}>School District</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copy-right">
        <div className="container">
          &copy; {moment().format("YYYY")}
          <Link className="mx-2" to={"/"}>
            MyCityPlace.Com
          </Link>
          Trademarks and brands are the property of MyCityPlace.
        </div>
      </div>
    </>
  );
}
