import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { logOut } from "store/reducers/Auth.reducer";

export default function Header() {
  const dispatch = useAppDispatch();
  const isAuthenticated = !!useAppSelector((s) => s.auth.token);
  const user = useAppSelector((s) => s.auth.user);
  const location = useLocation();
  const menu = React.useMemo(() => {
    return [
      {
        linkTo: "/",
        label: "Home",
      },
      {
        linkTo: "/one-percent-commission",
        label: "1% Commission",
      },
    ];
  }, []);

  const authMenu = React.useMemo(() => {
    if (isAuthenticated)
      return (
        <li className="dropdown">
          <a>
            {user?.firstName} {user?.lastName} <span className="caret"></span>
          </a>
          <ul className="dropdown-menu">
            <li>
              <a>Your Bookmark</a>
            </li>
            <li className="divider m-0"></li>
            <li onClick={() => dispatch(logOut())}>
              <a>Logout</a>
            </li>
          </ul>
        </li>
      );

    return (
      <li>
        <Link
          to="/login"
          className="flex justify-center items-center hover:border-t-transparent"
        >
          <i className="lnr lnr-user mr-1"></i>
          Login or Sign up
        </Link>
      </li>
    );
  }, [isAuthenticated, user]);

  return (
    <header className="main-header">
      <div className="container-fluid">
        <nav className="navbar navbar-default">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#app-navigation"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link to="/" className="logo">
              <img
                src={require("assets/images/logo-black-horizontal.png")}
                alt="logo"
              />
            </Link>
          </div>
          <div
            className="navbar-collapse collapse"
            role="navigation"
            id="app-navigation"
          >
            <ul className="nav navbar-nav">
              {menu.map((mn) => (
                <li
                  key={mn.linkTo}
                  className={`dropdown ${
                    location.pathname === mn.linkTo ? "active" : ""
                  }`}
                >
                  <Link data-toggle="dropdown" to={mn.linkTo}>
                    {mn.label}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="nav navbar-nav navbar-right">{authMenu}</ul>
          </div>
        </nav>
      </div>
    </header>
  );
}
