import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZipMatchResponse, ZipMatchResponsePaginatedResponse } from "models";
import set from "lodash/set";
import { fetchApartments } from "store/async-thunk/ApartmentSyncThunk";
import { PAGE_SIZE } from "common/Constants";

export type Apartment = ZipMatchResponse & { active?: boolean };

export interface ApartmentState {
  loading: boolean;
  page: number | undefined;
  perPage: number | undefined;
  data: Apartment[] | null | undefined;
  total: number | undefined;
}

const initialState: ApartmentState = {
  loading: false,
  page: 1,
  perPage: PAGE_SIZE,
  data: [],
  total: 0,
};

export const apartmentSlice = createSlice({
  name: "apartment",
  initialState,
  reducers: {
    setActive: (state, action: PayloadAction<number | undefined>) => {
      const index = action.payload;
      const activeIndex = state.data?.findIndex((d) => d.active);
      if (index !== activeIndex) {
        set(state, `data[${activeIndex}].active`, false);
        set(state, `data[${index}].active`, true);
      }
    },
    closePopup: (state, action: PayloadAction<number | undefined>) => {
      if ((state.data || [])[action.payload || 0].active)
        set(state, `data[${action.payload}].active`, false);
    },
    toggleItemBookmark: (state, action: PayloadAction<{recID?: number, bookmark: boolean}>) => {
      const itemIndex = state.data?.findIndex((d) => d.recID === action.payload.recID) ?? -1;
      if(itemIndex > -1){
        set(state, `data[${itemIndex}].isFavorite`, action.payload.bookmark);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApartments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchApartments.fulfilled,
      (state, action: PayloadAction<ZipMatchResponsePaginatedResponse>) => {
        state.loading = false;
        state.page = action.payload?.page;
        state.perPage = action.payload?.perPage;
        state.data = action.payload?.data?.map((i, idx) =>
          Object.assign(i, idx === 0 && { active: true })
        );
        state.total = action.payload?.total;
      }
    );
    builder.addCase(fetchApartments.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setActive, closePopup, toggleItemBookmark } = apartmentSlice.actions;
export default apartmentSlice.reducer;
