import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { getPersistConfig } from "redux-deep-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  FLUSH,
  PAUSE,
  persistReducer,
  persistStore,
  REHYDRATE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import onePercentCommission from "./reducers/OnePercentCommission.reducer";
import apartmentForm from "./reducers/ApartmentForm.reducer";
import apartment from "./reducers/Apartment.reducer";
import bookmark from './reducers/Bookmark.reducer';
import auth from './reducers/Auth.reducer';

const rootReducer = combineReducers({
  onePercentCommission,
  apartmentForm,
  apartment,
  bookmark,
  auth
});

const persistConfig = getPersistConfig({
  key: "root",
  storage,
  whitelist: [
    "auth.token",
    "auth.user",
    "apartmentForm.formValues.survey",
    "bookmark.data"
  ],
  rootReducer,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
