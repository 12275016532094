import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { login } from "store/async-thunk/AuthSyncThunk";
import { LoginPasswordModel } from "models";
import logo from "assets/images/logo-black-horizontal.png";

export default function Login() {
  const dispatch = useAppDispatch();
  const isAuthenticated = !!useAppSelector((s) => s.auth.token);
  const serverErrorMsg = useAppSelector((s) => s.auth.error);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginPasswordModel>();

  const onSubmit: SubmitHandler<LoginPasswordModel> = (body) =>
    dispatch(login(body));

  const signInComponent = React.useMemo(() => {
    if (isAuthenticated) return <Navigate to="/" />;
    return (
      <div className="pt-28 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-content-box">
                <div className="details">
                  <div className="mb-6">
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </div>
                  <div className="main-title">
                    <h1>
                      <span>Login</span>
                    </h1>
                  </div>
                  <form
                    className="login-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group">
                      <input
                        className="input-text"
                        placeholder="Email Address"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Email is not valid.",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="label-error">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="input-text"
                        placeholder="Password"
                        autoComplete="off"
                        {...register("password", {
                          required: "This field is required",
                        })}
                      />
                      {errors.password && (
                        <span className="label-error">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                    <div className="checkbox">
                      <Link
                        className="link-not-important pull-right"
                        to="/forgot-password"
                      >
                        Forgot Password
                      </Link>
                      <div className="clearfix"></div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="button-md button-theme btn-block"
                      >
                        login
                      </button>
                    </div>

                    {serverErrorMsg && (
                      <div className="alert alert-danger">
                        <a className="close" data-dismiss="alert">
                          ×
                        </a>
                        {serverErrorMsg}
                      </div>
                    )}
                  </form>
                </div>
                <div className="footer">
                  <span>
                    Don't have an account?
                    <Link to="/signup" className="ml-2">
                      Sign up now
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [isAuthenticated, serverErrorMsg, errors, handleSubmit, onSubmit]);

  return signInComponent;
}
