import React from "react";
import get from "lodash/get";
import {
  Link,
  LoaderFunctionArgs,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ResetPasswordModel } from "models";
import AuthService from "services/AuthService";
import logo from "assets/images/logo-black-horizontal.png";

type ResetPasswordInputs = ResetPasswordModel & {
  reTypePassword: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<{
    success?: boolean;
    msg?: string;
  }>({});

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswordInputs>();

  const validate = (value: string) =>
    value !== getValues("password")
      ? "Confirm password doesn't match"
      : undefined;

  const onSubmit: SubmitHandler<ResetPasswordInputs> = async (body) => {
    const id = params.get("id");
    if (!id) return;
    else {
      setLoading(true);
      setMessage({});
      try {
        const msg = await AuthService.resetPassword({ ...body, id } as ResetPasswordModel);
        setMessage({ success: true, msg });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (msg: any) {
        setMessage({ success: false, msg });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="content-area  pt-28 pb-20">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-content-box">
              <div className="details">
                <div className="mb-6">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>
                <div className="main-title">
                  <h1>
                    <span>Reset</span> Password
                  </h1>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <input
                      type="password"
                      className="input-text"
                      placeholder="New Password"
                      {...register("password", {
                        required: "This field is required",
                        minLength: {
                          value: 6,
                          message: "Password require at least 6 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <span className="label-error">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="input-text"
                      placeholder="Retype Password"
                      {...register("reTypePassword", {
                        required: "This field is required",
                        minLength: {
                          value: 6,
                          message: "Password require at least 6 characters",
                        },
                        validate,
                      })}
                    />
                    {errors.reTypePassword && (
                      <span className="label-error">
                        {errors.reTypePassword.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      disabled={loading}
                      className="button-md button-theme btn-block"
                    >
                      Submit
                    </button>
                  </div>

                  {message?.msg && (
                    <div
                      className={`alert alert-${
                        message.success ? "success" : "danger"
                      }`}
                    >
                      <a className="close">×</a>
                      {message?.msg}
                    </div>
                  )}
                </form>
              </div>
              <div className="footer">
                <span>
                  <Link to="/login">Back to login</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const loader = async ({ request }: LoaderFunctionArgs) => {
  const queryParams = new URLSearchParams(get(request.url.split("?"), "[1]"));
  const userId = queryParams.get("id");
  const navigate = () => {
    window.location.href = "/";
  };

  if (!userId) navigate();
  else {
    try {
      const valid = await AuthService.checkExpiryChangePassword(userId);
      if (!valid) navigate();
    } catch {
      navigate();
    }
  }
};

export { loader };
