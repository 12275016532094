import { ApiRequestOptions } from "./ApiRequestOptions";

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type APIConfig = {
    BASE: string;
    VERSION: string;
    TOKEN?: string | Resolver<string>;
    HEADERS?: Headers;
};

export const Configs: APIConfig = {
    // BASE: 'https://localhost:5001/api',
    BASE: 'http://18.223.148.181:5003/api',
    VERSION: '1',
    TOKEN: undefined,
    HEADERS: {
        'content-type': 'application/json',
    },
};