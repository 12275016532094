import { createAsyncThunk } from "@reduxjs/toolkit";
import get from 'lodash/get';
import { Apartment, toggleItemBookmark } from "store/reducers/Apartment.reducer";
import { add, Bookmark, remove } from "store/reducers/Bookmark.reducer";

export const toggleFavotite = createAsyncThunk(
    "apartment/toggleFavotite",
    async (params: {
        recID?: number,
        favId?: number
    }, { getState, dispatch }) => {
        const state: any = getState();
        const apartment: Apartment = get(state, 'apartment.data', []).find((x: Apartment) => x.recID === params.recID);
        const bookmark: Bookmark = {
            id: params.favId,
            baseMarkKey: apartment.propertymak,
            address: apartment.mdPropertyAddress,
            state: apartment.mdpropertystate,
            city: apartment.mdPropertyCity,
            zip: apartment.mdpropertyzip
        }

        // if is authenticated, need to call api before update the item
        dispatch(toggleItemBookmark({ recID: params.recID, bookmark: !apartment.isFavorite }));

        // adding to favorite list
        if (!apartment.isFavorite)
            dispatch(add(bookmark))
        else dispatch(remove(bookmark.id || (bookmark.baseMarkKey as string)))

        return true;
    },
);