import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import logo from "assets/images/logo-black-horizontal.png";
import { ForgotPasswordModel } from "models";
import AuthService from "services/AuthService";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState<{
    isSuccess?: boolean;
    message?: string;
  }>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordModel>();

  const onSubmit: SubmitHandler<ForgotPasswordModel> = async (body) => {
    setLoading(true);
    setMessage({});
    try {
      const res = await AuthService.forgotPassword(body);
      setMessage({ isSuccess: true, message: res });
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (message: any) {
      setMessage({ isSuccess: false, message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content-area pt-28 pb-20">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-content-box">
              <div className="details">
                <div className="mb-6">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>
                <div className="main-title">
                  <h1>
                    <span>Forgot</span> Password
                  </h1>
                </div>

                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <input
                      className="input-text"
                      placeholder="Email Address"
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Email is not valid.",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="label-error">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="button-md button-theme btn-block"
                      disabled={loading}
                    >
                      Send Me Email
                    </button>
                  </div>

                  {message?.message && (
                    <div
                      className={`alert alert-${
                        message.isSuccess ? "success" : "danger"
                      }`}
                    >
                      <a className="close">×</a>
                      {message?.message}
                    </div>
                  )}
                </form>
              </div>
              <div className="footer">
                <span>
                  <Link to="/login">Back to login</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
