import { GG_API_KEY } from "common/Constants";
import { get } from "lodash";
import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import GooglePlacesAutocompleteProps, {
  GooglePlacesAutocompleteHandle,
} from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";

type PlaceProps = Omit<
  GooglePlacesAutocompleteProps &
    React.RefAttributes<GooglePlacesAutocompleteHandle> & {
      isMulti?: boolean;
      onSelect?: (selected: ResponseSelect | ResponseSelect[] | null) => void;
    },
  "apiKey"
>;

export interface ResponseSelect {
  lat: number;
  lng: number;
  zip: string;
  city: string;
  state: string;
  address: string;
  place_id: string;
  searchType: string;
  source: any;
}

type SelectItem = {
  label: string;
  value: any;
};

export default function PlaceSelect({ isMulti = false, ...props }: PlaceProps) {
  const onChange = async (item: SelectItem | SelectItem[] | null) => {
    if (item !== null) {
      const options: SelectItem[] = item instanceof Array ? item : [item];
      const selected: ResponseSelect[] = await Promise.all(
        options.map(async (i) => {
          const place_id = i?.value?.place_id;
          const geocode = (await geocodeByPlaceId(place_id))[0];
          const res: ResponseSelect = {
            lat: geocode.geometry.location.lat(),
            lng: geocode.geometry.location.lng(),
            city: get(geocode, "address_components", []).filter(
              ({ types }) => types[0] === "locality"
            )[0]?.long_name,
            state: get(geocode, "address_components", []).filter(
              ({ types }) => types[0] === "administrative_area_level_1"
            )?.[0]?.short_name,
            zip: get(geocode, "address_components", []).filter(
              ({ types }) => types[0] === "postal_code"
            )?.[0]?.long_name,
            address: geocode.formatted_address,
            place_id,
            searchType: "",
            source: i,
          };
          res.searchType = res.zip ? "ZIP" : "CITY";
          return res;
        })
      );

      if (!isMulti) {
        props.onSelect && props.onSelect(selected[0]);
        return;
      }
      props.onSelect && props.onSelect(selected);
    }else {
      props.onSelect && props.onSelect(null);
    }
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiOptions={{ region: "us", language: "en", ...props.apiOptions }}
        apiKey={GG_API_KEY}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["us"],
          },
          types: ["postal_code", "locality", "administrative_area_level_1"],
          ...props.autocompletionRequest,
        }}
        selectProps={{
          ...props.selectProps,
          onChange,
          isMulti,
          styles: {
            option: (provided: any) => ({
              ...provided,
              textAlign: "left",
            }),
          },
        }}
      />
    </div>
  );
}
