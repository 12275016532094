export const SOCIAL_LINKS: { url: string; iconCls: string }[] = [
  {
    iconCls: "fa fa-rss",
    url: "",
  },
  {
    iconCls: "fa fa-google-plus",
    url: "",
  },
  {
    iconCls: "fa fa-linkedin",
    url: "",
  },
  {
    iconCls: "fa fa-twitter",
    url: "",
  },
  {
    iconCls: "fa fa-facebook",
    url: "",
  },
];

export const COMPANY_INFO: { url?: string; iconCls: string; text: string }[] = [
  {
    text: "111 Main Ave, Anaheim CA, 92808",
    iconCls: "fa fa-map-marker",
  },
  {
    text: "support@mycityplace.com",
    iconCls: "fa fa-envelope",
    url: "mailto:support@mycityplace.com",
  },
  {
    text: "714 747 3449",
    iconCls: "fa fa-phone",
    url: "tel:714 747 3449",
  },
  {
    text: "714 747 3449",
    iconCls: "fa fa-fax",
  },
];

export const FEATURES_IN_HOUSE: string[] = [
  "Air Conditioning",
  "Carpet",
  "Ceramic Tile",
  "Hardword fllor",
  "Eat in Kitchen",
  "Laundry room",
  "In-law apt",
  "Seprate Dining room",
  "Family room",
  "Great room",
  "Basement",
  "Formal Living room",
  "Fireplace",
  "Workshop",
  "Spa",
  "Lot of Windows",
];

export const THE_VIEWS: string[] = [
  "Water front/Water View",
  "On Golf Course/Golf Course View",
  "Country Club",
  "Mountain View",
  "Ocean View",
];
export const ROOMS: { value: number; label: string }[] = [
  1, 2, 3, 4, 5, 6, 7,
].map((r, index) => ({ label: `${r} Room${index > 0 ? "s" : ""}`, value: r }));

export const YOUR_COMMUNITIES: string[] = [
  "Clubhouse/activities",
  "With pools",
  "Golf course",
  "Basketball court",
  "Gated Community/Door man",
];

export const CONVINENCES: string[] = [
  "Super market",
  "School",
  "Hospital",
  "Doctor/Dentis",
  "Shopping",
  "Child care",
  "Recreation Park",
  "Restaurant/Entertainment",
  "Church",
  "Airport",
  "Public Transportation",
  "Highway",
];

export const CONSIDERATIONS: string[] = [
  "Pre-School",
  "Elementary",
  "Middle Shool",
  "High Shool",
  "Age/Condition",
  "Reputation",
  "Quality of Teachers",
  "Test Score",
  "Play Areas",
  "Curriculum",
  "Class Size",
  "Busing Distance",
];
export const DEAL_BREAKERS: string[] = [
  "Roads with heavy traffic",
  "Kids",
  "No front yard",
  "Small Lot",
];
export const WORK_TRAFFIC_FAR: string[] = [
  "15 Minutes",
  "30 Minutes",
  "45 Minutes",
  "More than 1 hour",
  "No reference",
];

export const AGES: Record<string, string> = {
  AgeGroup1825: "18-25",
  AgeGroup2635: "26-35",
  AgeGroup3645: "36-45",
  AgeGroup4655: "46-55",
  AgeGroup5665: "56-65",
  AgeGroup6675: "66-75",
  AgeGroup76: "76+",
};
export const KIDS_AGE: Record<string, string> = {
  NoChn0: "No Children",
  NoChn1: "1 Child",
  NoChn2: "2 Children",
  NoChn3: "3 Children",
  NoChn4: "4 Children",
  NoChn5: "5 Children",
  NoChn6: "6 Children",
  NoChn7: "7 Children",
};
export const PET_FRIENDLY: string[] = ["None", "Cat", "Dog", "Other"];

export const HOUSEHOLD_INCOME: Record<string, string> = {
  EstInc1: "Less than $15,000",
  EstInc2: "$15,000 - $19,999",
  EstInc3: "$20,000 - $29,999",
  EstInc4: "$30,000 - $39,999",
  EstInc5: "$40,000 - $49,999",
  EstInc6: "$50,000 - $59,999",
  EstInc7: "$60,000 - $69,999",
  EstInc8: "$70,000 - $79,999",
  EstInc9: "$80,000 - $89,999",
  EstIncA: "$90,000 - $99,999",
  EstIncB: "$100,000 - $124,999",
  EstIncC: "$125,000 - $149,999",
  EstIncD: "Greater than $149,999",
};

export const FIX_PRICES: { label: string; value: number }[] = [
  { label: "$500", value: 500 },
  { label: "$700", value: 700 },
  { label: "$900", value: 900 },
  { label: "$1100", value: 1100 },
  { label: "$1300", value: 1300 },
  { label: "$1500", value: 1500 },
  { label: "$1750", value: 1750 },
  { label: "$2000", value: 2000 },
  { label: "$2250", value: 2250 },
  { label: "$2750", value: 2750 },
  { label: "$3000", value: 3000 },
];

export const GG_API_KEY = "AIzaSyCrmEjp-9gAiYHcx_6D0kYrqcPBgL8crsM";
export const PAGE_SIZE = 200;