import React from "react";
import debounce from "lodash/debounce";

type CheckboxGroupProps = {
  options: any[];
  label: string;
  labelClassName?: string;
  name: string;
  value?: any[] | null | undefined;
  onChange?: (value: any[]) => void;
  delayValueUpdate?: number;
  disableOtherWhenSelectedValue?: any;
};

export default function CheckboxGroup({
  delayValueUpdate = 1000,
  ...props
}: CheckboxGroupProps) {
  const [checked, setChecked] = React.useState<any[]>([]);
  const debounceSetValue = React.useRef(
    debounce(async (checked: any[]) => {
      props.onChange && props.onChange(checked);
    }, delayValueUpdate)
  ).current;

  const onChangeState = (value: any) => {
    let checkedValues = [];

    if (checked.includes(value))
      checkedValues = checked.filter((x) => x !== value);
    else checkedValues = [...checked, value];
    setChecked(checkedValues);
    debounceSetValue(checkedValues);
  };
  const disabled = React.useMemo(() => {
    return checked.includes(props.disableOtherWhenSelectedValue);
  }, [checked, props.disableOtherWhenSelectedValue]);

  React.useEffect(() => {
    setChecked(props.value || []);
  }, [JSON.stringify(props.value)]);

  React.useEffect(() => {
    if (disabled && props.disableOtherWhenSelectedValue) {
      setChecked([props.disableOtherWhenSelectedValue]);
      debounceSetValue([props.disableOtherWhenSelectedValue]);
    }
  }, [disabled, props.disableOtherWhenSelectedValue, debounceSetValue, setChecked]);

  return (
    <>
      <label className={props.labelClassName}>{props.label}</label>
      {props.options.map((i, idx) => (
        <div key={idx} className="checkbox checkbox-theme checkbox-circle">
          <input
            onChange={() => onChangeState(i)}
            checked={checked.indexOf(i) > -1}
            name={props.name}
            id={`${props.name}${idx}`}
            value={i}
            type="checkbox"
            disabled={disabled && props.disableOtherWhenSelectedValue !== i}
          />
          <label htmlFor={`${props.name}${idx}`}>{i}</label>
        </div>
      ))}
    </>
  );
}
