import { MapContainer, TileLayer } from "react-leaflet";
import { useAppSelector } from "store";
import CustomMarker from "./CustomMarker";

export default function ApartmentMap() {
  const { data } = useAppSelector((s) => s.apartment || {});

  return (
    <MapContainer
      center={[39.8097343, -98.5556199]}
      zoom={5}
      scrollWheelZoom={false}
      className="h-[calc(100vh-460px)] w-full"
      closePopupOnClick={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
      />
      {(data || []).map((item, index) => (
        <CustomMarker key={item.recID} item={item} index={index} />
      ))}
    </MapContainer>
  );
}
