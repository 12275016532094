import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toggleFavotite } from "store/async-thunk/BookmarkSyncThunk";

export interface BookmarkState {
    loading: boolean
    data: Bookmark[]
}

type NullableString = string | null | undefined;

export interface Bookmark {
    id?: number,
    baseMarkKey: NullableString,
    address: NullableString;
    state: NullableString;
    city: NullableString;
    zip: NullableString
}

const initialState: BookmarkState = {
    loading: false,
    data: [],
};

export const bookmarkSlice = createSlice({
    name: "bookmark",
    initialState,
    reducers: {
        add: (state, action: PayloadAction<Bookmark>) => {
            state.data = [...state.data, action.payload];
        },
        remove: (state, action: PayloadAction<number | string>) => {
            state.data = state.data.filter(x => {
                if (typeof action.payload === 'number') return x.id !== action.payload;
                return x.baseMarkKey !== action.payload;
            });
        },
        clear: (state) => {
            state.data = state.data.filter(x => x.id);
        }
    },
    extraReducers: builder => {
        builder.addCase(toggleFavotite.fulfilled, () => {

        })
    }
});

export const { add, remove, clear } = bookmarkSlice.actions;
export default bookmarkSlice.reducer;
