import { Layout } from "components";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import {
  ForgotPassword,
  Home,
  Login,
  OnePercentCommission,
  ResetPassword,
  SignUp,
} from "pages";
import { loader } from "pages/auth/ResetPassword";
import "assets/styles/index.scss";
import "leaflet/dist/leaflet.css";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route
          path="one-percent-commission"
          element={<OnePercentCommission />}
        />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route
        path="reset-password/*"
        element={<ResetPassword />}
        loader={loader}
      />
    </>
  )
);

const App = () =>  <RouterProvider router={router} />;
export default App;
