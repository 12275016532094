import SearchForm from "./SearchForm";

export default function ApartmentLeftSideContainer() {
  return (
    <div className="h-[calc(100vh-60px)] flex flex-col">
      <SearchForm />
      <div className="my-6 border-b border-gray-200 hidden-sm hidden-xs" />
    </div>
  );
}
