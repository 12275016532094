import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Address } from "models";

export interface OnePercentCommissionState {
  value: number;
  addresses: Address[]
}

const initialState: OnePercentCommissionState = {
  value: 0,
  addresses: []
};

export const onePercentComissionSlice = createSlice({
  name: "onePercentComission",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } =
  onePercentComissionSlice.actions;
export default onePercentComissionSlice.reducer;
