
import request from 'api';
import { AddressAutoComplete, OnePercentCommissionValues } from 'models';

export default class OnePercentCommissionService {
    static getAddresses = (term?: string) => request<AddressAutoComplete[]>({
        method: 'GET',
        url: '/onepercentcomission/address',
        query: {
            term
        }
    })

    static getProperty = (
        address?: string,
        city?: string,
        state?: string,
        postalCode?: string,
        mak?: string
    ) => request<OnePercentCommissionValues>({
        method: 'GET',
        url: '/onepercentcomission/property',
        query: {
            address,
            city,
            state,
            postalCode,
            mak
        }
    })
}