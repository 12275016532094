import {
  ApartmentLeftSideContainer,
  ApartmentMap,
  ApartmentList,
} from "components";
import React from "react";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "store";
import { fetchApartments } from "store/async-thunk/ApartmentSyncThunk";
import { useAppDispatch } from "store";

export default function Home() {
  const dispacth = useAppDispatch();
  const { formValues } = useAppSelector((s) => s.apartmentForm);

  React.useEffect(() => {
    if (!isEmpty(formValues)) dispacth(fetchApartments(formValues));
  }, [formValues, dispacth]);

  return (
    <>
      <div className="map-content content-area container-fluid">
        <div className="col-xs-12 col-sm-12 col-md-5 col-md-push-7 col-lg-9 col-lg-push-3 flex flex-col h-[calc(100vh-75px)]">
          <div className="row h-[calc(100vh-460px)]">
            <ApartmentMap />
          </div>
          <div className="row flex-1 overflow-y-hidden border-b border-gray-200">
            <ApartmentList />
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-7 col-md-pull-5 col-lg-3 col-lg-pull-9 map-content-sidebar flex flex-col px-0">
          <ApartmentLeftSideContainer />
        </div>
      </div>

      <div className="py-44">
        <div className="container wow fadeInUp delay-03s">
          <div className="row">
            <div className="col-12 text-center pb-40">
              <h1 className="uppercase text-5xl text-gray-700">
                How does it work?
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-technology-1"></i>
                </div>
                <div className="detail">
                  <h3 className="uppercase">Apply search criterias</h3>
                  <p>
                    See who specializes in your area, has the most reviews and
                    the right experience to meet your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-apartment"></i>
                </div>
                <div className="detail">
                  <h3 className="uppercase">
                    We help you find the perfect appartment
                  </h3>
                  <p>
                    More than 10,000 customers buy or sell a home with us each
                    year. We help people and homes find each together.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-people"></i>
                </div>
                <div className="detail">
                  <h3 className="uppercase">Contact for Availability</h3>
                  <p>
                    Our specialists can help you get started on that home
                    project. Find paint colors, that perfect tile and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partners-block py-20">
        <div className="container wow fadeInUp delay-03s">
          <h1 className="uppercase text-5xl text-gray-700">
            About My City Place AI
          </h1>
          <div className="row mt-8">
            <div className="col-md-12">
              <p className="text-3xl">
                The engine will make milions of caculation based on your input
                to provide you the most matches
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
