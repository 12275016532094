import { createAsyncThunk } from "@reduxjs/toolkit";
import get from "lodash/get";
import { ZipRequestModel } from "models";
import ApartmentService from "services/AppartmentService";
import { Bookmark } from "store/reducers/Bookmark.reducer";

export const fetchApartments = createAsyncThunk(
  "apartment/fetchApartments",
  async (body: ZipRequestModel, { getState }) => {
    const state = getState();
    const favorites = get(state, 'bookmark.data', []) as Bookmark[];
    const response = await ApartmentService.getApartments({
      ...body,
      favorites: favorites.map(f => f.baseMarkKey as string)
    });
    return response;
  }
);
