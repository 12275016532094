import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SurveyRequestModel, ZipRequestModel } from "models";

export interface FormState {
  formValues: ZipRequestModel;
}

const initialState: FormState = {
  formValues: {},
};

export const apartmentFormSlice = createSlice({
  name: "apartmentForm",
  initialState,
  reducers: {
    updateParams: (state, action: PayloadAction<ZipRequestModel>) => {
      state.formValues = Object.assign({}, state.formValues, {
        ...action.payload,
      });
    },
    updateSurvey: (state, action: PayloadAction<SurveyRequestModel>) => {
      state.formValues.page = 1;
      state.formValues.survey = Object.assign({}, state.formValues.survey, {
        ...action.payload,
      });
    },
  },
});

export const { updateParams, updateSurvey } = apartmentFormSlice.actions;
export default apartmentFormSlice.reducer;
