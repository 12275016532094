import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginPasswordModel } from "models";
import AuthService from "services/AuthService";
import { setToken } from "store/reducers/Auth.reducer";

export const login = createAsyncThunk(
    "login",
    async (body: LoginPasswordModel, { dispatch }) => {
        const res = await AuthService.login(body);
        dispatch(setToken(res.token));
        const user = await AuthService.getUser();
        return user;
    }
);