import { Link } from "react-router-dom";
import logo from "assets/images/logo-black-horizontal.png";

export default function SignUp() {
  return (
    <div className="container pt-28 pb-20">
      <div className="row">
        <div className="col-lg-12">
          <div className="form-content-box">
            <div className="details">
              <div className="mb-6">
                <Link to="/">
                  <img src={logo} />
                </Link>
              </div>
              <div className="main-title">
                <h1>
                  <span>Signup</span>
                </h1>
              </div>
              <form>
                <div className="form-group">
                  <input
                    name="firstName"
                    className="input-text"
                    placeholder="Firstname"
                  />
                </div>
                <div className="form-group">
                  <input
                    name="lastName"
                    className="input-text"
                    placeholder="Lastname"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="input-text"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="companyName"
                    className="input-text"
                    placeholder="Company Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="phoneNumber"
                    className="input-text"
                    placeholder="Phone number"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    className="input-text"
                    placeholder="Password"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="reTypePassword"
                    className="input-text"
                    placeholder="Retype Password"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="button-md button-theme btn-block"
                  >
                    Signup
                  </button>
                </div>

                {/* <div className="alert alert-danger">
                  <a className="close" data-dismiss="alert">
                    ×
                  </a>
                  <span className="err-mess"></span>
                </div> */}
              </form>
            </div>

            <div className="footer">
              <span>
                Already have account? <Link to="/login">Back to login</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
