import request from 'api';
import { ForgotPasswordModel, LoginPasswordModel, ResetPasswordModel, UserProfileModel } from 'models';

export default class AuthService {
    static login = (body: LoginPasswordModel) => request<{ token: string }>({
        url: '/auth/login',
        method: 'POST',
        body
    })

    static getUser = () => request<UserProfileModel>({
        url: '/auth/profile',
        method: 'GET'
    })

    static forgotPassword = (body: ForgotPasswordModel) => request<string>({
        url: '/auth/forgot-password',
        method: 'POST',
        body
    })

    static checkExpiryChangePassword = (userId: string) => request<boolean>({
        url: '/auth/check-expiry-change-password',
        method: 'GET',
        query: {
            userId
        }
    })

    static resetPassword = (body: ResetPasswordModel) => request<string>({
        method: "POST",
        url: '/auth/reset-password',
        body
    })
}