import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { get, set } from "lodash";
import { store } from 'store'
import { Configs } from "./APIConfig";
import { ApiRequestOptions } from "./ApiRequestOptions";

const instance = axios.create({
    baseURL: Configs.BASE,
    headers: Configs.HEADERS
})

const request = <T>(options: ApiRequestOptions): Promise<T> => {
    return new Promise(async (resolve, reject) => {
        const state = store.getState();
        const token = get(state, 'auth.token', '');
        const source = axios.CancelToken.source();
        const requestConfig: AxiosRequestConfig = {
            url: options.url,
            headers: options.headers,
            data: options.body ?? options.formData,
            method: options.method,
            cancelToken: source.token,
            params: options.query
        };

        // Inject token
        set(requestConfig, 'headers.Authorization', token ? `Bearer ${token}` : null);

        try {
            const response = await instance.request<T>(requestConfig);
            resolve(response?.data);
        } catch (error) {
            const axiosError = error as AxiosError<T>;
            if (axiosError.response) {
                return reject(axiosError.response?.data);
            }
            reject(error)
        }
    })
}

export default request;