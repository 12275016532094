import request from 'api';
import { ZipMatchResponsePaginatedResponse } from 'models';
import { ZipRequestModel } from 'models/models/ZipRequestModel';

export default class ApartmentService {
    static getApartments = (body: ZipRequestModel) => request<ZipMatchResponsePaginatedResponse>({
        url: '/zip/get-apartments',
        method: 'POST',
        body
    })
}