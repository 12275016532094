import React from "react";
import { useAppDispatch, useAppSelector } from "store";
import { PAGE_SIZE } from "common/Constants";
import { updateParams } from "store/reducers/ApartmentForm.reducer";
import ApartmentItem from "./ApartmentItem";
import { get } from "lodash";

export default function ApartmentList() {
  const dispacth = useAppDispatch();
  const {
    data = [],
    loading,
    total = 0,
    page: currentPage = 1,
  } = useAppSelector((s) => s.apartment);

  const totalPage = React.useMemo(() => {
    return Math.round(total / PAGE_SIZE);
  }, [total]);

  const handlePageChange = React.useCallback(
    (fetchNext?: boolean) => {
      const page = fetchNext ? currentPage + 1 : currentPage - 1;
      dispacth(updateParams({ page }));
    },
    [currentPage, dispacth]
  );

  const goNext = React.useCallback(() => {
    if (currentPage < totalPage) handlePageChange(true);
    return;
  }, [totalPage, currentPage, handlePageChange]);

  const backPrevious = React.useCallback(() => {
    if (currentPage > 1) handlePageChange();
    return;
  }, [currentPage, handlePageChange]);

  const resultComponent = React.useMemo(() => {
    if (loading)
      return (
        <i className="fa fa-refresh fa-spin right-5 top-6 text-2xl ml-2"></i>
      );
    if (!loading && data?.length) {
      return (
        <div className="flex flex-1 flex-row justify-between items-center">
          <span className="ml-2 lowercase">({total})</span>
          <span className="text-right ml-3">
            <span
              onClick={backPrevious}
              className="capitalize mr-5 cursor-pointer text-lime-600"
            >
              <i className="fa fa-angle-left text-3xl" />
            </span>
            <span className="lowercase">
              {currentPage} of {totalPage} pages
            </span>
            <span
              onClick={goNext}
              className="capitalize cursor-pointer text-lime-600 ml-5"
            >
              <i className="fa fa-angle-right text-3xl" />
            </span>
          </span>
        </div>
      );
    }
  }, [loading, data?.length, backPrevious, currentPage, goNext, totalPage, total]);

  return (
    <>
      <div className="my-3 mr-6 hidden-sm hidden-xs">
        <h2 className="flex items-center uppercase font-semibold">
          Properties {resultComponent}
        </h2>
      </div>

      <div className="table-fix-head h-[calc(100%-40px)]">
        <table>
          <thead>
            <tr>
              <th>Property Address</th>
              <th>Address State, City, Zip</th>
              <th>Rank</th>
              <th className="w-[150px]">Action</th>
            </tr>
          </thead>
          <tbody>
            {get(data, "length", 0) > 0 &&
              (data || []).map((item, index) => (
                <ApartmentItem
                  index={index}
                  apartment={item}
                  key={item.recID}
                />
              ))}
          </tbody>
        </table>
        {get(data, "length", 0) === 0 && (
          <div className="px-6 text-center pt-28">
            <h4 className="text-gray-500">Data matching will appear here!</h4>
          </div>
        )}
      </div>
    </>
  );
}
