import React from "react";
import { useAppDispatch } from "store";
import { ZipMatchResponse } from "models";
import { toggleFavotite } from "store/async-thunk/BookmarkSyncThunk";
import { setActive } from "store/reducers/Apartment.reducer";

type ApartmentItemProps = {
  apartment: ZipMatchResponse;
  index: number;
};

export default function ApartmentItem({
  apartment,
  index = 0,
}: ApartmentItemProps) {
  const dispatch = useAppDispatch();
  const showMaker = React.useCallback(
    () => dispatch(setActive(index)),
    [index, dispatch]
  );
  const toggleBookmark = () => {
    dispatch(toggleFavotite({ recID: apartment.recID, favId: 0 }));
    showMaker();
  };

  return (
    <tr>
      <td className="property-content">
        <h1 className="title">
          <a href="properties-details.html">{apartment.mdPropertyAddress}</a>
        </h1>
      </td>
      <td>
        <div onClick={showMaker} className="flex cursor-pointer">
          <i className="fa fa-map-marker mr-2 text-red-500 text-3xl"></i>
          {apartment.mdPropertyCity}, {apartment.mdpropertystate},
          {apartment.mdpropertyzip}
        </div>
      </td>
      <td>
        <h3>
          Match Ranking:{" "}
          <span className="font-semibold text-red-600">
            {apartment.ranking}
          </span>
        </h3>
      </td>
      <td>
        <div className="flex items-center">
          <span className="mr-6">
            <span className="cursor-pointer" onClick={toggleBookmark}>
              <i
                className={`fa icon fa-heart text-3xl ${
                  apartment.isFavorite
                    ? "text-red-500"
                    : "text-gray-300 hover:text-gray-400"
                }`}
              />
            </span>
          </span>
          <span className="left btn button-sm button-theme">
            <i className="fa fa-address-book icon"></i> Contact
          </span>
        </div>
      </td>
    </tr>
  );
}
