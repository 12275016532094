import { ZipMatchResponse } from "models";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Marker, useMap, Popup } from "react-leaflet";
import L from "leaflet";
import { Link } from "react-router-dom";
import { useAppDispatch } from "store";
import { closePopup } from "store/reducers/Apartment.reducer";
import { toggleFavotite } from "store/async-thunk/BookmarkSyncThunk";

type CustomMarkerProps = {
  item: ZipMatchResponse & { active?: boolean };
  index: number;
};

const _icon = L.divIcon({
  html: '<img style="width: 100% !important" src="img/home.png">',
  iconSize: [36, 46],
  iconAnchor: [18, 0],
  className: "",
});

function ChangeView({ center }: any) {
  const map = useMap();
  let zoom = map.getZoom();

  /** Set zoom when active the marker */
  if (zoom < 13) {
    zoom = 13;
    map.setZoom(zoom);
  }
  const point = map.project(center, zoom);
  const newPoint = point.subtract([0, 50]);
  const newLatlng = map.unproject(newPoint, zoom);
  map.panTo(newLatlng, { animate: true });
  return null;
}

const CustomMarker = ({ item = {}, index = 0 }: CustomMarkerProps) => {
  const { active, mdPropertyLatitude = 0, mdPropertyLongitude = 0 } = item;
  const map = useMap();
  const popupRef = useRef<L.Popup>(null);
  const dispatch = useAppDispatch();

  const close = useCallback(() => {
    map.closePopup(popupRef.current as any);
    dispatch(closePopup(index));
  }, [index, popupRef, dispatch, map]);

  useEffect(() => {
    if (active && map)
      map.openPopup(popupRef.current as any, [
        mdPropertyLatitude,
        mdPropertyLongitude,
      ]);
  }, [active, map, popupRef, mdPropertyLatitude, mdPropertyLongitude]);

  const toogleBookmark = () => dispatch(toggleFavotite({ recID: item.recID, favId: 0 }));

  const UpdateMapView = useMemo(() => {
    if(!active) return null;
    return <ChangeView center={[mdPropertyLatitude, mdPropertyLongitude]} />
  }, [active, mdPropertyLatitude, mdPropertyLongitude]);

  return (
    <>
      {UpdateMapView}
      <Marker position={[mdPropertyLatitude, mdPropertyLongitude]} icon={_icon}>
        <Popup ref={popupRef} closeButton={false} autoPan={false}>
          <a href="/#" onClick={close} className="leaflet-popup-close-button">
            x
          </a>
          <div className="flex flex-row">
            <div className="flex-1">
              <div className="mb-2">
                <span className="font-semibold">Address: </span>
                <Link className="font-semibold" to="/apartment-detail">
                  {item.mdPropertyAddress}
                </Link>
              </div>
              <div className="mb-2">
                <span className="font-semibold">State: </span>
                {item.mdpropertystate}
              </div>
              <div className="mb-2">
                <span className="font-semibold">City: </span>
                {item.mdPropertyCity}
              </div>
              <div>
                <span className="font-semibold">Zip: </span>
                {item.mdpropertyzip}
              </div>
            </div>
            <div className="w-14">
              <span className="cursor-pointer" onClick={toogleBookmark}>
                <i
                  className={`fa icon fa-heart text-3xl ${
                    item.isFavorite
                      ? "text-red-500"
                      : "text-gray-300 hover:text-gray-400"
                  }`}
                />
              </span>
            </div>
          </div>
        </Popup>
      </Marker>
    </>
  );
};

export default CustomMarker;
